<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Benutzer bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form name="userform" @submit.prevent="save" v-model="formValid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-switch v-model="active" label="Benutzer aktiv" color="success"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="5">
                    <v-text-field
                      label="Vorname"
                      name="firstname"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="firstname"
                      required
                      :rules="requiredRule"
                      @input="createKuerzel"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="5">
                    <v-text-field
                      label="Nachname"
                      name="lastname"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="lastname"
                      required
                      :rules="requiredRule"
                      @input="createKuerzel"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      label="Kürzel"
                      name="kuerzel"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="kuerzel"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12">
                    <v-text-field
                      label="Email"
                      name="email"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="email"
                      required
                      :rules="emailRules"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12">
                    <v-text-field
                      label="Passwort"
                      name="password"
                      type="password"
                      color="purple"
                      :clearable="true"
                      v-model="password"
                      v-bind:required="id == null ? 'required' : ''"
                      :rules="id == null ? requiredRule : []"
                    />
                  </v-col>
                </v-row>
               
                <v-row v-if="!checkAccess('superuser')">
                  <v-col cols="12" sm="12">
                     <v-select
                      name="rights"
                      v-model="apps.rights"
                      :items="mandantenList" 
                      attach
                      chips
                      label="Mandatenrechte"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>

                <v-divider />

                <v-container>
                  <h2>Mail Einstellungen</h2>
                  <p>
                    Benutzer Einstellungen für den Mailversand an Kunden.
                  </p>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-switch
                        v-model="mailSettings.useusermail"
                        label="Verwende Benutzer Mail Einstellungen"
                        color="green"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-show="mailSettings.useusermail">
                  <h3>Absender</h3>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Absendername"
                        name="mailnamefrom"
                        type="text"
                        v-model="mailSettings.mailnamefrom"
                        :rules="mailSettings.useusermail ? requiredRule : []"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Absenderemail"
                        name="mailadressfrom"
                        type="text"
                        v-model="mailSettings.mailadressfrom"
                        :rules="mailSettings.useusermail ? emailRules : []"
                      />
                    </v-col>
                  </v-row>
                  <h3>SMTP Server Einstellungen</h3>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-switch
                        label="Externen Mailserver verwenden"
                        v-model="mailSettings.useexternalsmtp"
                        color="green"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-show="mailSettings.useexternalsmtp">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="SMTP Mailserver"
                        name="smtpserver"
                        type="text"
                        v-model="mailSettings.smtpserver"
                      />
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                        label="SMTP Port"
                        name="smtpport"
                        type="number"
                        v-model="mailSettings.smtpport"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-checkbox
                        label="TLS aktivieren"
                        v-model="mailSettings.smtptls"
                        color="green"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-show="mailSettings.useexternalsmtp">
                    <v-col cols="12" sm="4">
                      <v-checkbox
                        label="SMTP Authentifizierung"
                        v-model="mailSettings.smtpauth"
                        color="green"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-show="mailSettings.smtpauth"
                        label="SMTP User"
                        name="smtpuser"
                        type="text"
                        v-model="mailSettings.smtpuser"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-show="mailSettings.smtpauth"
                        label="SMTP Passwort"
                        name="smtppass"
                        type="password"
                        v-model="mailSettings.smtppass"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ path: '/user' }"
              color="error"
              type="button"
              name="cancel"
              >Abbrechen</v-btn
            >
            <v-spacer />
            <v-btn
              @click="save"
              color="success"
              name="submit"
              type="submit"
              :disabled="!formValid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from "@/services/UserService.js";
//import MailSettingsForm from "@/components/settings/MailSettingsForm";

export default {
  name: "UserForm",
  components: {},
  props: ["user"],
  data: () => ({
    firstname: "",
    lastname: "",
    kuerzel: "",
    email: "",
    password: "",
    id: null,
    active: true,
    apps: {
      rights: []
    },
    mailSettings: {
      useusermail: false,
      mailnamefrom: "",
      mailadressfrom: "",
      useexternalsmtp: false,
      smtpserver: "",
      smtpport: 25,
      smtptls: false,
      smtpauth: false,
      smtpuser: "",
      smtppass: ""
    },
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid"
    ],
    formValid: false
  }),
  computed: {
    absenderName() {
      return this.firstname + " " + this.lastname;
    },
    mandantenList(){
      let mandanten = [];
        
        const mList = this.$store.getters["mandant/getMandantenList"];
        mList.forEach((item) => {
          mandanten.push({ text: item.firmenname, value: item._id });
        });

        return mandanten;
    }
  },
  methods: {
    checkAccess(role) {
     
      if (!this.user) {
        return false;
      }
      if (
        this.user.apps.roles.indexOf(
          this.$store.getters["user/userRoles"].userRole.SuperUser
        ) >= 0
      ) {
        return true;
      } else if (this.user.apps.roles.indexOf(role) >= 0) {
        return true;
      } else {
        return false;
      }
    },
    createKuerzel() {
      this.kuerzel =
        String(this.firstname)
          .substring(0, 2)
          .toUpperCase() +
        String(this.lastname)
          .substring(0, 2)
          .toUpperCase();
    },
    save() {
      UserService.saveUser({
        id: this.id,
        data: {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          kuerzel: this.kuerzel,
          mailSettings: this.mailSettings,
          apps: this.apps,
          active: this.active
        }
      })
        .then(() => {
          //if success redirect to list
          this.$router.push({ name: "user" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Benutzer gespeichert",
            text: "Benutzer erfolgreich gespeichert"
          });
        })
        .catch((error) => {
          //on error alert error
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        });
    }
  },
  created() {
    //console.log(this.mitarbeiter);
    if (this.user != null) {
      this.id = this.user._id;
      this.firstname = this.user.firstname;
      this.lastname = this.user.lastname;
      this.email = this.user.email;
      this.kuerzel = this.user.kuerzel;
      this.apps = this.user.apps;
      this.active = this.user.active;
      if (this.user.mailSettings) {
        this.mailSettings = this.user.mailSettings;
      }
    } else {
      this.mailSettings.mailnamefrom = this.absenderName;
      this.mailSettings.mailadressfrom = this.email;
    }

    if(this.mandantenList.length == 1){
      this.user.apps.rights.push(this.mandantenList[0].value);
    }
  }
};
</script>
